const ordersLogsReducer = (state = { ordersLogsList : {ordersLogs: [], maxRows: 0} }, action) => {
  switch (action.type) {
    case "GET_ORDERS_LOGS_SUCCESS":
      return { ...state, ordersLogsList: action.payload }
    default:
      return state
  }
}

export default ordersLogsReducer
