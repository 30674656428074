import React from "react"
import Toggle from "react-toggle"
import { Sun, Moon } from "react-feather"
import "assets/scss/plugins/forms/switch/react-toggle.scss"
import "react-toggle/style.css"

class ToggleDarkMode extends React.Component {
  state = {

  }

  handleClick = () => {
    if(this.props.activeMode === 'dark'){
      this.props.changeMode('semi-dark')
    }else{
      this.props.changeMode('dark')
    }
  }

  render() {
    return (
      <label className="react-toggle-wrapper">
        <Toggle
          checked={this.props.activeMode === 'dark' ? true : false}
          onChange={this.handleClick}
          className="night-mode-switcher"
          icons={{
            checked: (
              <Moon
                size={13}
                style={{ position: "absolute", top: "-2px" }}
              />
            ),
            unchecked: (
              <Sun
                size={13}
                style={{ position: "absolute", top: "-2px" }}
              />
            )
          }} />
        <span className="label-text"></span>
      </label>
    )
  }
}
export default ToggleDarkMode
