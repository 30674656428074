import axios from 'axios'
import {store} from '../../redux/storeConfig/store.js'
import { logoutWithApi } from '../../redux/actions/auth/loginActions';
import { toast } from "react-toastify"

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  }
})

axiosConfig.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    if(401 === error.response.status){
      toast.error("Your session has expired.", {
      toastId: '401'
    })
      store.dispatch(logoutWithApi())
    }else{
      return Promise.reject(error)
    }
  })

export default axiosConfig
