const locationsReducer = (state = { usersList : [], selectedUser: null, lockers: null }, action) => {
  switch (action.type) {
    case "GET_USERS_LIST_SUCCESS":
      return { ...state, usersList: action.payload }
    case "GET_USER_DETAILS_SUCCESS":
      return { ...state, selectedUser: action.payload }
    case "GET_USER_PLACES_LOCKERS":
      return { ...state, lockers: action.payload }
    case "GET_USER_KPIS_SUCCESS":
      return { ...state, kpis: action.payload }
    default:
      return state
  }
}

export default locationsReducer
