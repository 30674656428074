const assetCategoriesReducer = (state = { assetCategoriesList: [], selectedAssetCategory: null }, action) => {
  switch (action.type) {
    case "GET_ASSET_CATEGORIES_LIST_SUCCESS":
      return { ...state, assetCategoriesList: action.payload }
    case "GET_ASSET_CATEGORY_DETAILS_SUCCESS":
      return { ...state, selectedAssetCategory: action.payload }
    default:
      return state
  }
}

export default assetCategoriesReducer
