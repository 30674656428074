import React from "react"
import { connect } from "react-redux"
import { withTranslation } from 'react-i18next'
import { history } from "../../../history"
import {
  Bell,
  AlertCircle
} from 'react-feather'
import { getAssetWarnings } from 'redux/actions/assets'
import { toast } from "react-toastify"
import {
  Badge,
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media
} from 'reactstrap'
import PerfectScrollbar from "react-perfect-scrollbar"
import Moment from 'react-moment'

class Notifications extends React.Component {
  componentDidMount(){
    this.props.dispatch(getAssetWarnings(this.props.accessToken, true)).catch(() => {
      toast.error(this.props.t("userNotifications.errorFetchingNotifications"))
    })

    this.refresh = setInterval(function(){
      this.reloadData()
    }.bind(this), 15000);
  }

  componentWillUnmount(){
    clearInterval(this.refresh)
  }

  reloadData = () => {
    this.props.dispatch(getAssetWarnings(this.props.accessToken, false)).catch(() => {
      toast.error(this.props.t("userNotifications.errorFetchingNotifications"))
    })
  }

  handleClick = (item) => {
    history.push('/assets/details/'+item.id)

  }

  render() {
    const t = this.props.t
    return (
          <UncontrolledDropdown
            tag="li"
            className="dropdown-notification nav-item mr-1"
          >
            <DropdownToggle tag="a" className="nav-link nav-link-label">
              <Bell
                className="navbar-icon"
                size={22}/>
              {this.props.notifications && this.props.notifications.length > 0 &&
                <Badge pill color="danger" className="badge-up pulse-red">
                  {" "}
                  {this.props.notifications.length}{" "}
                </Badge>
              }
            </DropdownToggle>
            <DropdownMenu tag="ul" right className="dropdown-menu-media">
              <li className="notifications-menu-header">
                <div className="notifications-header mt-0">
                  <span>{t("userNotifications.notifications")}</span>
                </div>
              </li>
              <PerfectScrollbar
                className="media-list overflow-hidden position-relative"
                options={{
                  wheelPropagation: false
                }}>
                {this.props.notifications && this.props.notifications.map((item, i) =>
                  <DropdownItem
                    className="d-flex justify-content-between notif-dropdown-item"
                    key={i}
                    onClick={() => this.handleClick(item)}>
                    <Media className="d-flex align-items-start">
                      <Media left href="#">
                        <AlertCircle
                          className="font-medium-5 danger"
                          size={21}
                        />
                      </Media>
                      <Media body>
                        <Media heading className="media-heading notifications-item-name" tag="h5">
                          {item.name}
                        </Media>
                          {item.status === 4 && item.tagStatus === 0 &&
                            <p className="notification-text">{t("userNotifications.shouldBeDetected")}</p>
                          }
                          {item.status === 6 && item.tagStatus === 1 &&
                            <p className="notification-text">{t("userNotifications.shouldBeUndetected")}</p>
                          }
                      </Media>
                      <small>
                          <Moment fromNow>{item.tagStatusChangeAt}</Moment>
                      </small>
                    </Media>
                  </DropdownItem>
                )}
              </PerfectScrollbar>
              {/*<li className="dropdown-menu-footer notifications-footer">
                <DropdownItem tag="a" className="p-1 text-center">
                  <span className="align-middle">Read all notifications</span>
                </DropdownItem>
              </li>*/}
            </DropdownMenu>
          </UncontrolledDropdown>
    )
  }
}

const mapStateToProps = state => {
  console.log(state)
  return {
    accessToken: state.auth.login.accessToken,
    notifications: state.assets.assetsWarnings,
  }
}

export default connect(mapStateToProps)(withTranslation('common')(Notifications))
