const quoteReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_QUOTE_SUCCESS":
      return action.payload
    default:
      return state
  }
}

export default quoteReducer
