const alertsReducer = (state = { alertsList : [], alerts_count: 0, expired_count: 0}, action) => {
  switch (action.type) {
    case "GET_ALERTS_SUCCESS":
      return { ...state, alertsList: action.payload }
    case "GET_EXPIRED_COUNT_SUCCESS":
      return { ...state, expired_count: action.payload }
    case "GET_ALERT_COUNT_SUCCESS":
      return { ...state, alerts_count: action.payload }
    default:
      return state
  }
}

export default alertsReducer
