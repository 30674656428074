const userGroupsReducer = (state = { userGroupsList : [], selectedUserGroup: null }, action) => {
  switch (action.type) {
    case "GET_USER_GROUP_LIST_SUCCESS":
      return { ...state, userGroupsList: action.payload }
    case "GET_USER_GROUP_DETAILS_SUCCESS":
      return { ...state, selectedUserGroup: action.payload }
    default:
      return state
  }
}

export default userGroupsReducer
