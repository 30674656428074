const assetsReducer = (state = { assetsList: [], assetsWarnings: [], selectedAsset: null, assetsLogs: [], selectedAssetLogs: [], assetKpis: [] }, action) => {
  switch (action.type) {
    case "GET_ASSETS_LIST_SUCCESS":
      return { ...state, assetsList: action.payload }
    case "GET_ASSETS_WARNINGS_SUCCESS":
      return { ...state, assetsWarnings: action.payload }
    case "GET_ASSET_DETAILS_SUCCESS":
      return { ...state, selectedAsset: action.payload }
    case "GET_ASSETS_LOGS_SUCCESS":
      return { ...state, assetsLogs: action.payload }
    case "GET_SINGLE_ASSET_LOGS_SUCCESS":
      return { ...state, selectedAssetLogs: action.payload }
    case "GET_ASSET_KPIS_SUCCESS":
      return { ...state, assetKpis: action.payload }
    default:
      return state
  }
}

export default assetsReducer
