const serviceStatusReducer = (state = { serviceStatus : [] }, action) => {
  switch (action.type) {
    case "GET_SERVICE_STATUS_SUCCESS":
    console.log(action.payload)
      return { ...state, serviceStatus: action.payload }
    default:
      return state
  }
}

export default serviceStatusReducer
