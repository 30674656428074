const schedulesReducer = (state = { schedulesList: [], selectedSchedule: null }, action) => {
  switch (action.type) {
    case "GET_SCHEDULES_LIST_SUCCESS":
      return { ...state, schedulesList: action.payload }
    case "GET_SCHEDULE_DETAILS_SUCCESS":
      return { ...state, selectedSchedule: action.payload }
    default:
      return state
  }
}

export default schedulesReducer
