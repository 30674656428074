import axios from "../api"
import XLSX from "xlsx"

export const exportUsers = (usersList) => new Promise(function(resolve, reject){
  function parseLockers(lockers){
    let locker_exportable = ''
      Array.from(lockers).map(item => {
        locker_exportable = locker_exportable + item.place.name + ' - ' + item.number
      })
      return locker_exportable
  }

  let dataToExport = []
  usersList.map(item => {
    return dataToExport.push({
      name: item.name,
      number: item.number,
      cardNumber: item.cardNumber,
      ccms: item.ccms,
      ccms_name: item.ccms_name,
      login: item.login,
      building: item.building,
      floor: item.floor,
      project: item.project,
      email: item.email,
      card: item.card,
      pin: item.pin,
      obs: item.obs,
      validUntil: item.validUntil,
      userType: item.userType,
      lockers: parseLockers(item.lockers)
    })
  })

  let fileName ="utilizadores.xlsx"
  let wb = XLSX.utils.json_to_sheet(dataToExport)
  let wbout = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wbout, wb, 'users')
  XLSX.writeFile(wbout, fileName)
})

export const cleaningMode = (token, place_id) => new Promise(function(resolve, reject){
  axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
  axios.get('api/julian/openFreeLockersByPlaceId/1')
  .then(res => {
    resolve()
  })
  .catch(error => {
    reject()
  })
})

export const enableMaintenanceMode = (token, place_id) => new Promise(function(resolve, reject){
  axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
  axios.get('api/julian/disableAllLockersByPlaceId/1')
  .then(res => {
      resolve()
  })
  .catch(error => {
    reject()
  })
})

export const disableMaintenanceMode = (token, place_id) => new Promise(function(resolve, reject){
  axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
  axios.get('api/julian/enableAllLockersByPlaceId/1')
  .then(res => {
      resolve()
  })
  .catch(error => {
    reject()
  })
})

export const emergencyMode = (token, place_id) => new Promise(function(resolve, reject){
  axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
  axios.get('api/julian/openAllLockersByPlaceId/1')
  .then(res => {
      resolve()
  })
  .catch(error => {
    reject()
  })
})

export const openLockerRequest = (token, locker_id) => new Promise(function(resolve, reject){
  console.log('id enviado para o pedido - ' + locker_id)
  if(locker_id == 1 ||
     locker_id == 2 ||
     locker_id == 3 ||
     locker_id == 4 ||
     locker_id == 5 ||
     locker_id == 6 ||
     locker_id == 7 ||
     locker_id == 8 ||
     locker_id == 9 ||
     locker_id == 10 ||
     locker_id == 11 ||
     locker_id == 12 ||
     locker_id == 13 ||
     locker_id == 14 ||
     locker_id == 15 ||
     locker_id == 16 ||
     locker_id == 17 ||
     locker_id == 18 ){
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/julian/openLockerById/' + locker_id)
    .then(res => {
      console.log(res)
        if(res.error) {
            reject()
        }
        resolve()
    })
    .catch(error => {
      reject()
    })
  }else{
    reject()
  }
})
